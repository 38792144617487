import { Window } from "@progress/kendo-react-dialogs";
import styled from "styled-components";
import { BodyBold16, Body14 } from "src/styles/_typography";
import { color } from "src/styles/_colors";
import emptyStateImage from "src/images/emptyStateImage.svg";

const EmptyStateWindow = () => {
  return (
    <StyledWindow
      width={396}
      minimizeButton={() => null}
      maximizeButton={() => null}
      closeButton={() => null}
    >
      <NullResultContainer>
        <img src={emptyStateImage} alt="No results" />
        <NoResults>No results Found</NoResults>
        <TryAgain>
          Try searching with different keywords or adjusting your filters
        </TryAgain>
      </NullResultContainer>
    </StyledWindow>
  );
};

export default EmptyStateWindow;
const NoResults = styled.h3`
  margin-bottom: 8px;
  ${BodyBold16}
  color:${color.gray50}
`;
const TryAgain = styled.p`
  ${Body14}
  color:${color.gray50};
  margin: 0;
  text-align: center;
`;
const StyledWindow = styled(Window)`
  background-color: white;
  border-radius: 16px;
  max-width: 396px;
  padding: 16px 16px 24px;
  > :first-child {
    padding: 0;
    border: none;
    height: 0;
  }
`;
const NullResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

import { useState, useMemo, useEffect, useRef } from "react";
import {
  Grid,
  GridToolbar,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { useErrorContext } from "src/contexts/ErrorContext";
import { ERROR_TOAST_MESSAGE } from "src/constants/SharedConstant";
import { Typography } from "@progress/kendo-react-common";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { PageState } from "src/interfaces/types";
import { useTableColumns } from "./hooks/useTableColumns";
import { TableProps } from "./type";
import DialogBox from "src/components/dialogBox/dialogBox";
import ConfirmModal from "src/components/confirmModal/confirmModal";
import { downloadLightIcon, xIcon } from "@progress/kendo-svg-icons";
import { GRID_STATES } from "src/utils/constants";
import EmptyStateWindow from "./EmptyStateWindow";

const EDIT_FIELD = "inEdit";

const Table = ({
  data,
  isLoading,
  error,
  reqFields,
  initialPageState,
  initialPageSort,
  exportFileName,
  tableHeight,
  onRowClick,
  onItemChange,
  updatedData,
  onUndo = undefined,
  gridSkeletonRowCount = 100,
}: TableProps) => {
  const [page, setPage] = useState<PageState>(initialPageState);
  const [sort, setSort] = useState<SortDescriptor[]>(initialPageSort);
  const { setErrorMessage } = useErrorContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const [componentTop, setComponentTop] = useState(150);

  const isEmptyDataState = () => {
    return !data?.results?.length && data?.isSuccess;
  };

  const getLoadingState = () => {
    if (isLoading) return GRID_STATES.LOADING;
    if (data?.results?.length > 0) return GRID_STATES.DATA_LOADED;
    if (isEmptyDataState()) return GRID_STATES.EMPTY_DATA;
  };

  const loadingState = getLoadingState();

  useEffect(() => {
    if (error) {
      setErrorMessage(ERROR_TOAST_MESSAGE);
      throw error;
    }
  }, [error, setErrorMessage]);

  useEffect(() => {
    const updateTop = () => {
      if (tableRef.current) {
        const componentTop = tableRef.current.getBoundingClientRect().top;
        setComponentTop(componentTop);
      }
    };
    updateTop();
    window.addEventListener("resize", updateTop);
    return () => {
      window.removeEventListener("resize", updateTop);
    };
  }, [data?.results]);

  const results = useMemo(() => {
    return updatedData || data?.results || [];
  }, [data?.results, updatedData]);

  const columns = useTableColumns({
    reqFields,
    loadingState: loadingState || "",
  });

  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      try {
        _export.current.save();
      } catch {
        setErrorMessage(ERROR_TOAST_MESSAGE);
        throw error;
      }
    }
  };

  const undoChange = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmPress = () => {
    setIsDialogOpen(false);
    if (onUndo) {
      onUndo();
    }
  };

  const pageChange = (event: GridPageChangeEvent) => {
    const take = event.page.take;
    setPage({ ...event.page, take });
  };

  const orderData = () => {
    if (loadingState === GRID_STATES.DATA_LOADED) {
      return orderBy(results, sort);
    }
    return Array.from({ length: gridSkeletonRowCount }).map(() => {
      return reqFields?.reduce((acc: { [key: string]: null }, { field }) => {
        acc[field] = null;
        return acc;
      }, {});
    });
  };

  if (error) return <Typography.p>Error: {error}</Typography.p>;

  return (
    <>
      {loadingState && (
        <div ref={tableRef} id="ge-kendo-grid">
          {loadingState === GRID_STATES.EMPTY_DATA && <EmptyStateWindow />}
          <Tooltip openDelay={100} position="top" anchorElement="target">
            <ExcelExport
              ref={_export}
              data={orderData()}
              fileName={exportFileName}
            >
              <Grid
                style={{
                  maxHeight:
                    tableHeight || `calc(100vh - ${componentTop}px - 20px)`,
                  margin: "auto",
                  maxWidth: "94%",
                }}
                data={orderData()}
                skip={page.skip}
                take={page.take}
                total={results.length}
                pageable={{
                  buttonCount: 4,
                  type: "input",
                  pageSizes: [5, 50, 100],
                }}
                onPageChange={pageChange}
                rowHeight={50}
                editField={EDIT_FIELD}
                resizable={true}
                navigatable={true}
                sortable={{ mode: "multiple", allowUnsort: true }}
                sort={sort}
                onSortChange={(e) => setSort(e.sort)}
                onRowClick={onRowClick}
                onItemChange={onItemChange}
              >
                <GridToolbar className="k-align-items-flex-end toolbar-header">
                  {onUndo !== undefined && (
                    <Button
                      themeColor="secondary"
                      fillMode="outline"
                      svgIcon={xIcon}
                      onClick={undoChange}
                    >
                      Undo
                    </Button>
                  )}
                  <Button
                    themeColor="secondary"
                    fillMode="outline"
                    svgIcon={downloadLightIcon}
                    onClick={excelExport}
                  >
                    Export
                  </Button>
                </GridToolbar>
                {columns}
              </Grid>
            </ExcelExport>
          </Tooltip>
          <DialogBox
            isOpen={isDialogOpen}
            onClose={handleCloseDialog}
            title="Are you sure you want to Undo?"
          >
            <ConfirmModal
              handleClose={handleCloseDialog}
              handleConfirm={handleConfirmPress}
            />
          </DialogBox>
        </div>
      )}
    </>
  );
};

export default Table;

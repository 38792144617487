import { useMemo } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import classNames from "classnames";
import TableColCell from "../TableColCell";
import { ColumnField } from "../type";
import { HeaderCell } from "../HeaderCell";
import { GRID_STATES } from "src/utils/constants";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Skeleton } from "@progress/kendo-react-indicators";

interface UseColumnsProps {
  reqFields?: ColumnField[];
  loadingState: string;
}

export const useTableColumns = ({
  reqFields,
  loadingState,
}: UseColumnsProps) => {
  return useMemo(() => {
    return reqFields?.map((field) => {
      const headerClass = classNames("custom-header", {
        "editable-header": field.editable,
      });
      const tdClass = classNames({
        editable: field.editable,
      });
      const animation = loadingState === GRID_STATES.EMPTY_DATA ? false : true;

      const renderCell = (
        loadingState: string,
        props: GridCellProps,
        field: ColumnField
      ) => {
        if (loadingState === GRID_STATES.DATA_LOADED) {
          return (
            <TableColCell
              {...props}
              inEdit={props.dataItem.inEdit}
              isCustomFields={field?.customFields}
              isEditable={field.editable || false}
            />
          );
        }
        return (
          <td>
            <Skeleton animation={animation} />
          </td>
        );
      };

      const renderHeader = (loadingState: string) => {
        if (loadingState === GRID_STATES.DATA_LOADED) {
          return HeaderCell;
        }
        return () => <Skeleton animation={animation} />;
      };
      return (
        <GridColumn
          key={field.headerName}
          cell={(props) => renderCell(loadingState, props, field)}
          field={field.field}
          title={field.headerName}
          width={field.width || "auto"}
          editable={field.editable || false}
          locked={field.locked || false}
          resizable={!field.locked}
          headerClassName={
            loadingState === GRID_STATES.DATA_LOADED
              ? headerClass
              : "custom-header"
          }
          className={tdClass}
          headerCell={renderHeader(loadingState)}
        />
      );
    });
  }, [reqFields, loadingState]);
};

export const color = {
  black: "#000000",
  primary: "#c8102e",
  white: "#fff",
  green: "#1F7A40",
  lightGreen: "#3c7846",
  primaryGradient: "linear-gradient(180deg, #ea2139 0%, #c8102e 100%)",
  primaryOn: "#E03A3D",
  // ---------Katami Gray Colors-----------
  gray30: "#2c3337",
  gray50: "#6c7275",
  gray75: "#b7b9bc",
  gray90: "#e5e5e3",
  gray95: "#f0eeea",
  gray96: "#f0eeeb",
  gray100: "#f9f8f6",
};
